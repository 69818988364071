import { createClient } from "src/lib/supabase/client";

export async function getTenant(domain: string) {
  const client = createClient();
  const req = await client.from("tenants").select().eq("studio_domain", domain).maybeSingle();
  return req;
}

export async function getUserTenants(profileId: number) {
  const client = createClient();
  const req = await client
    .from("tenants")
    .select("*, profiles_tenants!inner(profile_id)")
    .eq("profiles_tenants.profile_id", profileId);
  return req;
}
